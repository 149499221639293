import React, {useEffect} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {create} from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import {SnackbarProvider} from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import {createTheme} from 'src/theme';
import Routes from 'src/Routes';

import {hot} from 'react-hot-loader/root';
import packageJson from "../package.json";

const history = createBrowserHistory();
const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const useStyles = makeStyles(() =>
    createStyles({
      '@global': {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%',
          width: '100%',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        },
        '#root': {
          height: '100%',
          width: '100%'
        }
      }
    })
);

function App() {
  useStyles();

  const {settings} = useSettings();

  useEffect(() => {
    console.log("version : " + packageJson.version);
  }, []);

  return (
      <ThemeProvider theme={createTheme(settings)}>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={1} autoHideDuration={2500}>
                <Router history={history}>
                  <Auth>
                    <ScrollReset/>
                    <GoogleAnalytics/>
                    {/* <CookiesNotification /> */}
                    {/* <SettingsNotification /> */}
                    <Routes/>
                  </Auth>
                </Router>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
      </ThemeProvider>
  );
}

export default hot(App);
