/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import RateReviewIcon from '@material-ui/icons/RateReview';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EcoIcon from "@material-ui/icons/Eco";
import FaceIcon from '@material-ui/icons/Face';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import {
  ShoppingCart as ShoppingCartIcon,
  BarChart as BarChartIcon,
  Users as UsersIcon,
  Droplet as NutrientIcon,
  Slack

} from 'react-feather';
import Logo from 'src/components/Logo';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

import NavItem from './NavItem';

const navConfig = [
  {
    subheader: '',
    items: [
      {
        title: '대시보드',
        href: '/app/reports/dashboard'
      }
    ]
  },
  {
    subheader: '상품/판매관리',
    items: [
      {
        title: '상품관리',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: '상품 조회/수정',
            href: '/app/management/products'
          },
          {
            title: '상품 등록',
            href: '/app/management/products/create/0'
          },
          {
            title: '상품 일괄등록',
            href: '/app/management/products/bundleUpload'
          }
        ]
      },
      {
        title: '제품 관리',
        icon: PlaylistAddIcon,
        href: '/app/management/product-sku',
        items: [
          {
            title: '제품 조회/수정',
            href: '/app/management/product-sku'
          }
        ]
      },
      {
        title: '위탁사 관리',
        icon: Slack,
        href: '/app/management/partners',
        items: [
          {
            title: '파트너 조회/수정',
            href: '/app/management/partners'
          },
          {
            title: '파트너 등록',
            href: '/app/management/partners/create/new'
          },
          {
            title: '파트너 적립율 설정',
            href: '/app/management/benefits/create/new'
          }
        ]
      },
      {
        title: '판매관리',
        icon: ReceiptIcon,
        href: '/app/management/orders',
        items: [
          {
            title: '전체',
            href: '/app/management/orders/all'
          },
    /*      {
            title: '전체_CS',
            href: '/app/management/orders/all/cs'
          },*/

          // {
          //   title: '신규',
          //   href: '/app/management/orders/new'
          // },
    /*      {
            title: '입금 대기',
            href: '/app/management/orders/depoist-wait'
          },*/
          {
            title: '결제 완료',
            href: '/app/management/orders/pay-complete'
          },
          {
            title: '배송 준비',
            href: '/app/management/orders/delivery-prepare'
          },
          {
            title: '배송 중',
            href: '/app/management/orders/delivery-on'
          },
          {
            title: '배송 완료',
            href: '/app/management/orders/delivered'
          },
          {
            title: '취소 요청',
            href: '/app/management/orders/cancel-request'
          },
          {
            title: '취소 완료',
            href: '/app/management/orders/cancellation-complete'
          },
          {
            title: '반품 요청',
            href: '/app/management/orders/return-request'
          },
         /* {
            title: '반품 확인 중',
            href: '/app/management/orders/return-confirm'
          },
          {
            title: '반품 수거 중',
            href: '/app/management/orders/return-picking'
          },
          {
            title: '반품 수거 완료',
            href: '/app/management/orders/return-picked'
          },*/
          {
            title: '반품 완료',
            href: '/app/management/orders/return-complete'
          },
          {
            title: '교환 요청',
            href: '/app/management/orders/change-request'
          },
         /* {
            title: '교환 수거 중',
            href: '/app/management/orders/change-picking'
          },
          {
            title: '교환 수거 완료',
            href: '/app/management/orders/change-picked'
          },
          {
            title: '교환 완료',
            href: '/app/management/orders/exchange-completed'
          },
          {
            title: '교환 재배송 중',
            href: '/app/management/orders/redelivery-on'
          },
          {
            title: '구매 확정',
            href: '/app/management/orders/confirmation-of-purchase'
          }*/
          // {
          //   title: '환불 실패',
          //   href: '/app/management/orders/return-fail'
          // },

        ]
      },
      {
        title: '건식 관리',
        icon: ShoppingCartIcon,
        href: '/app/management/health-products',
        items: [
          {
            title: '상품 조회/수정',
            href: '/app/management/health-products'
          },
          {
            title: '상품 조회/수정 (네이버 코드 수정)',
            href: '/app/management/health-products-naver'
          }
        ]
      },
      {
        title: '영양소 관리',
        icon: NutrientIcon,
        href: '/app/management/nutrition',
        items: [
          {
            title: '영양소 조회/수정',
            href: '/app/management/nutrition'
          },
        ]
      },
    ]
  },
  {
    subheader: '거래/통계 관리',
    items: [
      {
        title: '상품별 통계 내역',
        icon: EqualizerIcon,
        href: '/app/management/payment/productsummary'
      },
      {
        title: '플랫폼별 통계 내역',
        icon: EqualizerIcon,
        href: '/app/management/payment/platformsummary'
      }
    ]
  },
  {
    subheader: '문의/리뷰관리',
    items: [
      {
        title: '문의 관리',
        icon: QuestionAnswerIcon,
        href: '/app/management/customer-service/inquiries'
      },
      {
        title: '리뷰 관리',
        icon: RateReviewIcon,
        href: '/app/management/customer-service/reviews'
      }
    ]
  },
  {
    subheader: '게시글 컨텐츠 관리',
    items: [
      {
        title: '공지사항 관리',
        icon: AnnouncementIcon,
        href: '/app/management/contents/notice'
      },
      {
        title: 'FAQ 관리',
        icon: LiveHelpIcon,
        href: '/app/management/contents/faq'
      },
      {
        title: '부아메라가 무엇인가요? 컨텐츠 관리',
        icon: ListAltIcon,
        href: '/app/management/contents/buamera-contents'
      }
    ]
  },
  {
    subheader: '배너 관리',
    items: [
      {
        title: '메인 배너 관리',
        icon: CalendarViewDayIcon,
        href: '/app/management/banners'
      }
    ]
  },
  {
    subheader: '계정 관리',
    items: [
      {
        title: '회원 관리',
        icon: UsersIcon,
        href: '/app/management/customers'
      },
      {
        title: '회원 수 조회',
        icon: ChildCareIcon,
        href: '/app/management/customers-count'
      },
    ]
  },
  {
    subheader: '마케팅 관리',
    items: [
      {
        title: '회원 조회',
        icon: UsersIcon,
        href: '/app/management/marketing-customers'
      }
    ]
  },
  {
    subheader: '카테고리 관리',
    items: [
      {
        title: '카테고리 관리',
        icon: AddToPhotosIcon,
        href: '/app/management/categories'
      }
    ]
  },
  {
    subheader: '프로모션 관리',
    items: [
      {
        title: '주문 금액 충족 프로모션 관리',
        icon: AddToPhotosIcon,
        href: '/app/management/promotion/target-price'
      },
      {
        title: '상품 프로모션 관리',
        icon: AddToPhotosIcon,
        href: '/app/management/promotion'
      }
    ]
  },{
    subheader: '노출 영역 관리',
    items: [
      {
        title: '카드 추가',
        icon: DynamicFeedIcon,
        href: '/app/management/cards'
      },
      {
        title: '카드 상품 카테고리 추가',
        icon: FormatListNumberedRtlIcon,
        href: '/app/management/exhibition/promotions'
      }
    ]
  },{
    subheader: '쿠폰 관리',
    items: [
      {
        title: '쿠폰 등록',
        icon: EcoIcon,
        href: '/app/management/coupons'
      },
    ]
  },{
    subheader: '앱 버전 관리',
    items: [
      {
        title: '버전 수정',
        icon: BarChartIcon,
        href: '/app/management/versions'
      },
    ]
  },
  // {
  //   subheader: 'Reports',
  //   items: [
  //     {
  //       title: 'Dashboard',
  //       icon: PieChartIcon,
  //       href: '/app/reports/dashboard'
  //     },
  //     {
  //       title: 'Dashboard Alternative',
  //       icon: BarChartIcon,
  //       href: '/app/reports/dashboard-alternative'
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Management',
  //   items: [
  //     {
  //       title: 'Customers',
  //       icon: UsersIcon,
  //       href: '/app/management/customers',
  //       items: [
  //         {
  //           title: 'List Customers',
  //           href: '/app/management/customers'
  //         },
  //         {
  //           title: 'View Customer',
  //           href: '/app/management/customers/1'
  //         },
  //         {
  //           title: 'Edit Customer',
  //           href: '/app/management/customers/1/edit'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Products',
  //       icon: ShoppingCartIcon,
  //       href: '/app/management/products',
  //       items: [
  //         {
  //           title: 'List Products',
  //           href: '/app/management/products'
  //         },
  //         {
  //           title: 'Create Product',
  //           href: '/app/management/products/create'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Orders',
  //       icon: FolderIcon,
  //       href: '/app/management/orders',
  //       items: [
  //         {
  //           title: 'List Orders',
  //           href: '/app/management/orders'
  //         },
  //         {
  //           title: 'View Order',
  //           href: '/app/management/orders/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Invoices',
  //       icon: ReceiptIcon,
  //       href: '/app/management/invoices',
  //       items: [
  //         {
  //           title: 'List Invoices',
  //           href: '/app/management/invoices'
  //         },
  //         {
  //           title: 'View Invoice',
  //           href: '/app/management/invoices/1'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Applications',
  //   items: [
  //     {
  //       title: 'Projects Platform',
  //       href: '/app/projects',
  //       icon: BriefcaseIcon,
  //       items: [
  //         {
  //           title: 'Overview',
  //           href: '/app/projects/overview'
  //         },
  //         {
  //           title: 'Browse Projects',
  //           href: '/app/projects/browse'
  //         },
  //         {
  //           title: 'Create Project',
  //           href: '/app/projects/create'
  //         },
  //         {
  //           title: 'View Project',
  //           href: '/app/projects/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social Platform',
  //       href: '/app/social',
  //       icon: ShareIcon,
  //       items: [
  //         {
  //           title: 'Profile',
  //           href: '/app/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           href: '/app/social/feed'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Kanban',
  //       href: '/app/kanban',
  //       icon: TrelloIcon
  //     },
  //     {
  //       title: 'Mail',
  //       href: '/app/mail',
  //       icon: MailIcon,
  //       info: () => <Chip color="secondary" size="small" label="Updated" />
  //     },
  //     {
  //       title: 'Chat',
  //       href: '/app/chat',
  //       icon: MessageCircleIcon,
  //       info: () => <Chip color="secondary" size="small" label="Updated" />
  //     },
  //     {
  //       title: 'Calendar',
  //       href: '/app/calendar',
  //       icon: CalendarIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Auth',
  //   items: [
  //     {
  //       title: 'Login',
  //       href: '/login-unprotected',
  //       icon: LockIcon
  //     },
  //     {
  //       title: 'Register',
  //       href: '/register-unprotected',
  //       icon: UserPlusIcon
  //     },
  //     {
  //       title: 'Login: Guest Protected',
  //       href: '/login',
  //       icon: ShieldIcon
  //     },
  //     {
  //       title: 'Register: Guest Protected',
  //       href: '/register',
  //       icon: ShieldIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Pages',
  //   href: '/app/pages',
  //   items: [
  //     {
  //       title: 'Account',
  //       href: '/app/account',
  //       icon: UserIcon
  //     },
  //     {
  //       title: 'Error',
  //       href: '/404',
  //       icon: AlertCircleIcon
  //     },
  //     {
  //       title: 'Pricing',
  //       href: '/pricing',
  //       icon: DollarSignIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Extra',
  //   items: [
  //     {
  //       title: 'Charts',
  //       href: '/app/extra/charts',
  //       icon: BarChartIcon,
  //       items: [
  //         {
  //           title: 'Apex Charts',
  //           href: '/app/extra/charts/apex'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Forms',
  //       href: '/app/extra/forms',
  //       icon: EditIcon,
  //       items: [
  //         {
  //           title: 'Formik',
  //           href: '/app/extra/forms/formik'
  //         },
  //         {
  //           title: 'Redux Forms',
  //           href: '/app/extra/forms/redux'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Editors',
  //       href: '/app/extra/editors',
  //       icon: LayoutIcon,
  //       items: [
  //         {
  //           title: 'DraftJS Editor',
  //           href: '/app/extra/editors/draft-js'
  //         },
  //         {
  //           title: 'Quill Editor',
  //           href: '/app/extra/editors/quill'
  //         }
  //       ]
  //     }
  //   ]
  // }
];

function renderNavItems({ items, ...rest }) {
  return (
      <List disablePadding>
        {items.reduce(
            (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
            []
        )}
      </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
        <NavItem
            depth={depth}
            icon={item.icon}
            key={key}
            info={item.info}
            open={Boolean(open)}
            title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
    );
  } else {
    acc.push(
        <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            key={key}
            info={item.info}
            title={item.title}
        />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
      <Box height="100%" display="flex" flexDirection="column">
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Hidden lgUp>
            <Box p={2} display="flex" justifyContent="center">
              <RouterLink to="/app/reports/dashboard">
                <Logo />
              </RouterLink>
            </Box>
          </Hidden>
          <Divider />
          <Box p={2}>
            {navConfig.map(config => (
                <List
                    key={config.subheader}
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {config.subheader}
                      </ListSubheader>
                    }
                >
                  {renderNavItems({
                    items: config.items,
                    pathname: location.pathname
                  })}
                </List>
            ))}
          </Box>
        </PerfectScrollbar>
      </Box>
  );

  return (
      <>
        <Hidden lgUp>
          <Drawer
              anchor="left"
              classes={{ paper: classes.mobileDrawer }}
              onClose={onMobileClose}
              open={openMobile}
              variant="temporary"
          >
            {content}
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
              anchor="left"
              classes={{ paper: classes.desktopDrawer }}
              open
              variant="persistent"
          >
            {content}
          </Drawer>
        </Hidden>
      </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;

