import axios from 'src/utils/axios';
import _ from 'lodash';
import responseErrorFilter from 'src/utils/responseErrorFilter';

class CategoryService {
  // 카테고리 전체를 조회하는 API
  getCategories = async () => {
    try {
      const response = await axios.get('/api/categories');
      const result = _.groupBy(response.data, 'level');
      return result;
    } catch (error) {
      return responseErrorFilter(error);
    }
  };

  // 해당하는 카테고리의 자식 카테고리를 조회하는 API
  getChildrenCategories = async parentCategoryId => {
    try {
      const response = await axios.get(
        `/api/categories/${parentCategoryId}/children`
      );
      return response.data;
    } catch (error) {
      return responseErrorFilter(error);
    }
  };

  // 카테고리 업데이트
  updateCategory = async (categoryId, data) => {
    try {
      const response = await axios.put(`/api/categories/${categoryId}`, data);

      return response;
    } catch (error) {
      return responseErrorFilter(error);
    }
  };

  // 카테고리 삭제
  deleteCategory = async categoryId => {
    try {
      const response = await axios.delete(`/api/categories/${categoryId}`);
      return response;
    } catch (error) {
      return responseErrorFilter(error);
    }
  };

  // 카테고리 우선순위 변경
  updatePriority = async data => {
    try {
      const response = await axios.put(`/api/categories/priorities`, data);

      return response;
    } catch (error) {
      return responseErrorFilter(error);
    }
  };

  // 카테고리(부모) 등록
  registerParentCategory = async data => {
    try {
      const response = await axios.post(`/api/categories`, data);

      return response;
    } catch (error) {
      return responseErrorFilter(error);
    }
  };

  // 카테고리(자식) 등록
  registerChildrenCategory = async (parentId, data) => {
    try {
      const response = await axios.post(
        `/api/categories/${parentId}/children`,
        data
      );

      return response;
    } catch (error) {
      return responseErrorFilter(error);
    }
  };
}

const categoryService = new CategoryService();

export default categoryService;
