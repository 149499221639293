/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
// import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      // 기존 회원관리
      // {
      //   exact: true,
      //   path: '/app/management/customers',
      //   component: lazy(() => import('src/views/management/CustomerListView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/customers/:customerId',
      //   component: lazy(() => import('src/views/management/CustomerDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/customers/:customerId/edit',
      //   component: lazy(() => import('src/views/management/CustomerEditView'))
      // },

      /* 상품관리 */
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/management/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products2',
        component: lazy(() => import('src/views/management/ProductListView2'))
      },
      {
        exact: true,
        path: '/app/management/products/create/:productId',
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/products/modify/:productId',
        component: lazy(() => import('src/views/management/ProductModifyView'))
      },
      {
        exact: true,
        path: '/app/management/products/bundleUpload',
        component: lazy(() =>
          import('src/views/management/ProductBundleUploadView')
        )
      },

      /* 건식 관리 */
      {
        exact: true,
        path: '/app/management/health-products',
        component: lazy(() => import('src/views/management/HealthProductListView'))
      },

      {
        exact: true,
        path: '/app/management/health-products-naver',
        component: lazy(() => import('src/views/management/HealthProductListNaverView'))
      },

      /* 영양소 관리 관리 */
      {
        exact: true,
        path: '/app/management/nutrition',
        component: lazy(() => import('src/views/management/NutritionListView'))
      },


      /* 상품 옵션 관리 */
      {
        exact: true,
        path: '/app/management/product-sku',
        component: lazy(() => import('src/views/management/ProductSkuListView'))
      },

      /**
       * 파트너스 관리
       */
      {
        exact: true,
        path: '/app/management/partners', // 입점사 목록
        component: lazy(() => import('src/views/management/Partners/PartnersView')
        )
      },
      {
        exact: true,
        path: '/app/management/partners/create/:partnerId', // 입점사 등록
        component: lazy(() => import('src/views/management/Partners/newPartnersView')
        )
      },
      {
        exact: true,
        path: '/app/management/partners/modify/:partnerId', // 입점사 수정
        component: lazy(() => import('src/views/management/Partners/newPartnersView')
        )
      },

      /**
       * 적립율 관리
       */
      {
        exact: true,
        path: '/app/management/benefits', // 적립율 목록
        component: lazy(() => import('src/views/management/Partners/PartnersView')
        )
      },
      {
        exact: true,
        path: '/app/management/benefits/create/:benefitId', // 적립율 등록
        component: lazy(() => import('src/views/management/Partners/newBenefitView')
        )
      },
      {
        exact: true,
        path: '/app/management/benefits/modify/:benefitId', // 적립율 수정
        component: lazy(() => import('src/views/management/Partners/newBenefitView')
        )
      },


      /* 판매관리 */
      {
        exact: true,
        path: '/app/management/orders/new', // 판매관리 - 신규
        component: lazy(() =>
          import('src/views/management/orders/NewOrderView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/depoist-wait', // 판매관리 - 입금 대기
        component: lazy(() =>
          import('src/views/management/orders/DepositWaitView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/pay-complete', // 판매관리 - 결제 완료
        component: lazy(() =>
          import('src/views/management/orders/PayCompleteView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/delivery-prepare', // 판매관리 - 배송 준비
        component: lazy(() =>
          import('src/views/management/orders/DeliveryPrepareView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/delivery-on', // 판매관리 - 배송 중
        component: lazy(() =>
          import('src/views/management/orders/DeliveryOnView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/delivered', // 판매관리 - 배송 완료
        component: lazy(() =>
          import('src/views/management/orders/DeliveredView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/cancel-request', // 판매관리 - 취소 요청
        component: lazy(() =>
          import('src/views/management/orders/CancelRequestView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/cancellation-complete', // 판매관리 - 취소 완료
        component: lazy(() =>
          import('src/views/management/orders/CancellationCompleteView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/return-request', // 판매관리 - 반품 요청
        component: lazy(() =>
          import('src/views/management/orders/ReturnRequestView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/return-confirm', // 판매관리 - 반품 확인 중
        component: lazy(() =>
            import('src/views/management/orders/ReturnConfirmView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/return-picking', // 판매관리 - 반품 수거 중
        component: lazy(() =>
          import('src/views/management/orders/ReturnPickingView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/return-picked', // 판매관리 - 반품 수거 완료
        component: lazy(() =>
          import('src/views/management/orders/ReturnPickedView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/return-complete', // 판매관리 - 반품 완료
        component: lazy(() =>
          import('src/views/management/orders/ReturnCompleteView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/change-request', // 판매관리 - 교환 요청
        component: lazy(() =>
          import('src/views/management/orders/ChangeRequestView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/change-picking', // 판매관리 - 교환 수거 중
        component: lazy(() =>
          import('src/views/management/orders/ChangePickingView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/change-picked', // 판매관리 - 교환 수거 완료
        component: lazy(() =>
          import('src/views/management/orders/ChangePickedView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/exchange-completed', // 판매관리 - 교환 완료
        component: lazy(() =>
          import('src/views/management/orders/ExchangeCompletedView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/redelivery-on', // 판매관리 - 교환 재배송 중
        component: lazy(() =>
          import('src/views/management/orders/RedeliveryOnView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/confirmation-of-purchase', // 판매관리 - 구매 확정
        component: lazy(() =>
          import('src/views/management/orders/ConfirmationOfPurchaseView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/all', // 판매관리 - 전체
        component: lazy(() =>
          import('src/views/management/orders/AllOrderView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/all/cs', // 판매관리 - 전체
        component: lazy(() =>
            import('src/views/management/orders/AllCSOrderView')
        )
      },
      {
        exact: true,
        path: '/app/management/orders/detail/:orderCode', // 판매관리 - 상세 페이지
        component: lazy(() => import('src/views/management/orders/Detail'))
      },
      /* 배너 관리 */
      {
        exact: true,
        path: '/app/management/banners',
        component: lazy(() =>
          import('src/views/management/Banners/BannerInfoView')
        )
      },
      {
        exact: true,
        path: '/app/management/payment/productsummary', // 상품별 통계 내역
        component: lazy(() =>
            import('src/views/management/Payment/ProductSummary')
        )
      },
      {
        exact: true,
        path: '/app/management/payment/platformsummary', // 플랫폼 별 통계
        component: lazy(() =>
            import('src/views/management/Payment/PlatformProductSummary')
        )
      },

      {
        exact: true,
        path: '/app/management/customer-service/inquiries', // 문의/리뷰관리 - 문의관리
        component: lazy(() =>
          import('src/views/management/CustomerService/Inquiries')
        )
      },
      {
        exact: true,
        path: '/app/management/customer-service/reviews', // 문의/리뷰관리 - 리뷰관리
        component: lazy(() =>
          import('src/views/management/CustomerService/Reviews')
        )
      },
      {
        exact: true,
        path: '/app/management/contents/notice', // 게시글 컨텐츠 관리 - 공지사항 관리
        component: lazy(() =>
          import('src/views/management/Contents/NoticeView')
        )
      },
      {
        exact: true,
        path: '/app/management/contents/notice/:noticeId', // 게시글 컨텐츠 관리 - 공지사항 상세
        component: lazy(() =>
          import('src/views/management/Contents/NoticeDetailView')
        )
      },
      {
        exact: true,
        path: '/app/management/contents/faq', // 게시글 컨텐츠 관리 - FAQ 관리
        component: lazy(() => import('src/views/management/Contents/FAQView'))
      },
      {
        exact: true,
        path: '/app/management/contents/faq/:faqId', // 게시글 컨텐츠 관리 - FAQ 상세
        component: lazy(() =>
          import('src/views/management/Contents/FAQDetailView')
        )
      },
      {
        exact: true,
        path: '/app/management/contents/buamera-contents', // 게시글 컨텐츠 관리 - 부아메라가 무엇인가요? 컨텐츠 관리
        component: lazy(() =>
          import('src/views/management/Contents/BuameraContentsView')
        )
      },
      {
        exact: true,
        path: '/app/management/contents/buamera-contents/:contentId', // 게시글 컨텐츠 관리 - 부아메라 컨텐츠 상세 (논문 & 미디어)
        component: lazy(() =>
          import('src/views/management/Contents/BuameraDetailView')
        )
      },
      /* 회원관리 */
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() =>
          import('src/views/management/Customer/CustomerListView')
        )
      },
      {
        exact: true,
        path: '/app/management/customers-count',
        component: lazy(() =>
            import('src/views/management/Customer/CustomerCountView')
        )
      },
      {
        exact: true,
        path: '/app/management/marketing-customers',
        component: lazy(() =>
            import('src/views/management/Marketing/MarketingCustomerListView')
        )
      },
      /* 카테고리 관리 */
      {
        exact: true,
        path: '/app/management/categories',
        component: lazy(() =>
          import('src/views/management/Categories/CategoriesManagementView')
        )
      },
      /* 프로모션 관리 */
      {
        exact: true,
        path: '/app/management/promotion/target-price',
        component: lazy(() =>
          import('src/views/management/Promotion/TargetPrice/TargetPricePromotionView')
        )
      },
      {
        exact: true,
        path: '/app/management/promotion/target-price/:promotionId',
        component: lazy(() =>
          import('src/views/management/Promotion/TargetPrice/NewTargetPricePromotionView')
        )
      },
      {
        exact: true,
        path: '/app/management/promotion/target-price/detail/:promotionId',
        component: lazy(() =>
          import('src/views/management/Promotion/TargetPrice/TargetPricePromotionDetailView')
        )
      },
      {
        exact: true,
        path: '/app/management/promotion',
        component: lazy(() =>
            import('src/views/management/Promotion/PromotionView')
        )
      },
      {
        exact: true,
        path: '/app/management/promotion/detail/:promotionId',
        component: lazy(() =>
            import('src/views/management/Promotion/PromotionDetailView')
        )
      },
      {
        exact: true,
        path: '/app/management/promotion/:promotionId',
        component: lazy(() =>
            import('src/views/management/Promotion/NewPromotionView')
        )
      },

      /**
       * 노출 영역 관리
       */
      {
        exact: true,
        path: '/app/management/cards', // 카드 목록
        component: lazy(() => import('src/views/management/cards/CardsView')
        )
      },
      {
        exact: true,
        path: '/app/management/cards/:cardId', // 카드 상세 조회
        component: lazy(() => import('src/views/management/cards/NewCardView')
        )
      },


      /**
       * 프로모션 카테고리 관리
       */
      {
        exact: true,
        path: '/app/management/exhibition/promotions', // 프로모션 카테고리 목록
        component: lazy(() => import('src/views/management/exhibition/PromotionCategoriesView')
        )
      },
      {
        exact: true,
        path: '/app/management/exhibition/PromotionCategories/:categoryId', // 프로모션 카테고리 상세 조회
        component: lazy(() => import('src/views/management/exhibition/NewPromotionCategoryView')
        )
      },

      /**
       * 쿠폰 관리
       */
      {
        exact: true,
        path: '/app/management/coupons', // 쿠폰 목록
        component: lazy(() => import('src/views/management/Coupons/CouponsView')
        )
      },
      {
        exact: true,
        path: '/app/management/coupons/:couponId', // 쿠폰 등록
        component: lazy(() => import('src/views/management/Coupons/newCouponView')
        )
      },

      /**
       * 앱 버전 관리
       */
      {
        exact: true,
        path: '/app/management/versions',
        component: lazy(() => import('src/views/management/App/index'))
      },


      // {
      //   exact: true,
      //   path: '/app/management/customers/:customerId',
      //   component: lazy(() => import('src/views/management/CustomerDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/customers/:customerId/edit',
      //   component: lazy(() => import('src/views/management/CustomerEditView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/orders',
      //   component: lazy(() => import('src/views/management/OrderListView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/orders/:orderId',
      //   component: lazy(() => import('src/views/management/OrderDetailsView'))
      // },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/management/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/projects/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/projects/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  // {
  //   path: '/docs',
  //   layout: DocsLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/docs',
  //       component: () => <Redirect to="/docs/welcome" />
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/welcome',
  //       component: lazy(() => import('src/views/docs/WelcomeView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/getting-started',
  //       component: lazy(() => import('src/views/docs/GettingStartedView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/environment-variables',
  //       component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/deployment',
  //       component: lazy(() => import('src/views/docs/DeploymentView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/api-calls',
  //       component: lazy(() => import('src/views/docs/ApiCallsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/analytics',
  //       component: lazy(() => import('src/views/docs/AnalyticsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/authentication',
  //       component: lazy(() => import('src/views/docs/AuthenticationView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/routing',
  //       component: lazy(() => import('src/views/docs/RoutingView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/settings',
  //       component: lazy(() => import('src/views/docs/SettingsView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/state-management',
  //       component: lazy(() => import('src/views/docs/StateManagementView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/theming',
  //       component: lazy(() => import('src/views/docs/ThemingView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/support',
  //       component: lazy(() => import('src/views/docs/SupportView'))
  //     },
  //     {
  //       exact: true,
  //       path: '/docs/changelog',
  //       component: lazy(() => import('src/views/docs/ChangelogView'))
  //     },
  //     {
  //       component: () => <Redirect to="/404" />
  //     }
  //   ]
  // },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
