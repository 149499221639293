import categoryService from 'src/services/categoryService';

export const SET_CATEGORIES = '@category/set-category';

export function setCategories() {
  return async dispatch => {
    try {
      const categories = await categoryService.getCategories();
      dispatch({
        type: SET_CATEGORIES,
        payload: {
          categories
        }
      });
    } catch (error) {
      dispatch({
        type: SET_CATEGORIES,
        payload: {
          categories: []
        }
      });
    }
  };
}
