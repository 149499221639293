/* eslint-disable no-param-reassign */
import produce from 'immer';
import { SET_CATEGORIES } from 'src/actions/categoryActions';

const initialState = {
  categories: {
    1: [],
    2: [],
    3: [],
    4: []
  }
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES: {
      const { categories } = action.payload;

      return produce(state, draft => {
        draft.categories = categories;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
