import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCategories } from 'src/actions/categoryActions';

function BasicInformation() {
  const dispatch = useDispatch();

  const initCategories = async () => {
    dispatch(setCategories());
  };

  useEffect(() => {
    initCategories();
  }, []);

  return null;
}

export default BasicInformation;
