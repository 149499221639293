import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';

class AuthService {
  setAxiosInterceptors = ({onLogout}) => {
    axios.interceptors.response.use(
        response => response,
        error => {
          if (error.response && error.response.status === 401) {
            this.setSession(null);

            if (onLogout) {
              onLogout();
            }
          }

          return Promise.reject(error);
        }
    );
  };

  handleAuthentication() {
    const token = this.getToken();
    if (!token) {
      return;
    }

    if (this.isValidToken(token)) {
      this.setSession(token);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = async (username, password) => {
    try {
      const response = await axios.post('/auth', {
        username,
        password
      });
      console.log(response.data);
      this.setSession(response.data.token);
      return response.data.user;
    } catch (error) {
      if (error.response && error.response.data.error === 'invalid_grant') {
        throw new Error('계정 정보가 없습니다.');
      } else {
        throw new Error();
      }
    }
  };

  loginInWithToken = async () => {
    const response = await axios.get('/api/accounts/authentication');
    return response.data;
  };

  logout = () => {
    this.setSession(null);
  };

  setSession = token => {
    if (token) {
      localStorage.setItem('token', JSON.stringify(token));
      axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
    } else {
      localStorage.removeItem('token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return null;
    }
    return JSON.parse(token);
  };

  isValidToken = token => {
    if (!token) {
      return false;
    }

    const decoded = jwtDecode(token.access_token);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getToken();
}

const authService = new AuthService();

export default authService;
